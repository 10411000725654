import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';
import Hls from 'hls.js';
import useVisibility from '../../../utils/useVisibility';

const title = 'Mobile Apps That Rise to the Challenge';
const html = 'Designing a breakthrough app that seamlessly integrates across platforms requires more than one developer—it requires a team of elite designers and brilliant developers that make the magic happen.';

const Wrapper = styled.div`
  width: calc(100% - 80px);
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 192px;
  padding-bottom: 196px;
  background-color: white;

  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1260px) {
    padding-bottom: 157px;
  }
  @media (max-width: 1115px) {
    padding-bottom: 84px;
  }
  @media (max-width: 1065px) {
    padding-bottom: 47px;
  }
  @media (max-width: 1000px) {
    padding-top: 80px;
  }

  .inner {
    background: #007DBA;
    border-radius: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 42px;
    padding-bottom: 75px;
    padding-left: 94px;
    padding-right: 94px;
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;

    .wrap-image { 
      position: relative;
      .fix-width { 
        width: 341px;
      }
      img { 
        position: absolute;
        left: 0;
        width: 341px;
        top: -200px;
        
      }
      .wrap-video {
        position: absolute;
        left: 0;        
        top: -200px;
        overflow: hidden;
        border-radius: 50px;
        width: 336px;
        height: 687.719px;
        video {
          margin-left: -323px;
          height: 100%;
        }
      }
      // 150w/x = 336 / 687.719

      @media (max-width: 1000px) {
        .fix-width { 
          width: 150px;
        }
        img { 
          position: unset;
          width: 150px;
          top: 0;
        }
        .wrap-video {
          position: unset;
          left: 0;        
          top: -200px;
          overflow: hidden;
          border-radius: 23px;
          width: 150px;
          height: 307px;
          video {
            margin-left: -144px;
          }
        }
      }
      
    }
    .copy {
      padding-left: 75px;
      @media (max-width: 750px) {
        padding-left: 0px;
      }

      h2 {
        font-family: Oswald;
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 36px;
        color: #FFFFFF;
        margin-top: 0;
      }

      .text {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 36px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        b {
          font-weight: bold;
          color: #01426A;
        }
      }
    }
  }

  @media (max-width: 750px) {
    width: calc(100% - 40px);
    padding-right: 20px;
    padding-left: 20px;
    .inner {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 35px;
      padding-bottom: 35px;
      .wrap-image {
        display: none;
      }
    }
  }
`;

function SubHeader(props) {
  // const videoRef = useRef(null);

  const updateVisibility = (isVisible) => {
    if(!videoRef || !videoRef.current) {
      return;
    }
    if(isVisible) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };
  const [ isVisible, videoRef ] = useVisibility(100, 100, updateVisibility);
  let loadTime = null;
  
  const videoProgress = function() {
    if (videoRef && videoRef.current) {
      const percent = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      // console.log(`video progress ${percent}%`);
      if (percent > 96) {
        videoRef.current.currentTime = 9.12;
      }
      if (window.performance.now() - loadTime > 60000) {
        videoRef.current.removeEventListener('timeupdate', videoProgress);
      }
    }
  }

  const loadVideo = function() {
    if( videoRef.current ) {
      videoRef.current.addEventListener('timeupdate', videoProgress);
    }

    //TODO: Some sort of fallback option if Hls is NOT supported?
    if (Hls.isSupported()) {
      var video = videoRef.current;
      var hls = new Hls();
      // bind them together
      hls.attachMedia(video);
      hls.on(Hls.Events.MEDIA_ATTACHED, function() {
        hls.loadSource('/videos/phone-app/video-v20201030-01.m3u8');
      });
    }
  }

  useEffect( () => {
    loadTime = window.performance.now();
    loadVideo();
  }, []);

  return (
    <Wrapper>
      <div className="inner">
        <div className="wrap-image">
          <div className="fix-width"></div>
          {/* <img src={phone} /> */}
          <div className="wrap-video">
            <video ref={videoRef} onClick={ () => loadVideo() } muted="muted" loop />
            {/* <div className="visible-window" ref={visibleWindowRef}></div> */}
          </div>
        </div>
        <div className="copy">
          <h2>{title}</h2>
          <div className="text" dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
      </div>
    </Wrapper>
  );
}

export default SubHeader;